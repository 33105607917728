.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nairobi {
  background: url('components/media/nairobibg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.botswanabg {
  background: url('components/media/botswana.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutdges {
  background: url('components/media/aboutdges.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.agendabg {
  background: url('components/media/whitegfx.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.shadowbox {
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
}

/* From Uiverse.io by cssbuttons-io */ 
button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
 }
 
 button.learn-more {
  width: 12rem;
  height: auto;
 }
 
 button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
 }
 
 button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
 }
 
 button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
 }
 
 button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
 }
 
 button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #4a4b5e;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
 }
 
 button:hover .circle {
  width: 100%;
 }
 
 button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
 }
 
 button:hover .button-text {
  color: #fff;
 }

 .dealroom {
  background: url('./components/media/dealroom.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.investor {
  background: url('./components/media/investorimg.png');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.entrepreneur {
  background: url('./components/media/entrepreneurimg.png');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}